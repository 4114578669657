@import 'src/styles/services';

.root {
  width: 100%;
  max-width: 746px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: $color-white;
  text-align: center;
  counter-reset: item;
}

.title {
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: relative;
  z-index: 3;
}

.sliderWrapper {
  position: relative;
  margin-inline: -16px;
}

.slide {
  display: flex;
  flex-direction: column;
  gap: 44px;
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  opacity: 0.2;
  position: relative;
}

.slideLeft {
  transform: scale(0.8);
}

.slideRight {
  transform: scale(0.8);
}

.slideActive {
  transform: scale(1);
  opacity: 1;
  z-index: 3;
}

.slideImageWrapper {
  width: 220px;
  align-self: center;
  position: relative;
}

.slideImage {
  width: 100%;
  height: auto;
}

.slideDescription {
  --slidesPerView: 2.3;
  --popupPadding: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transform: scale(0.8);
  margin-inline: -50%;
  padding-inline: 16px;

  .slideActive & {
    opacity: 1;
    transform: scale(1);
  }

  .slideLeft & {
    transform-origin: left bottom;
  }

  .slideRight & {
    transform-origin: right bottom;
  }

  @include media-width-down($screen-xs) {
    margin-inline: -30%;
    font-size: 13px;
    line-height: 15.73px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 464px;
  z-index: 1;
}

.paginationItem {
  width: 12px;
  height: 12px;
  background-color: $color-grey;
  transition: background-color 0.3s ease-in-out;
}

.paginationItem:not(.paginationItemActive) {
  cursor: pointer;
}

.paginationItemActive {
  background-color: $color-red;
}

.buttonLeftIcon {
  transform: scale(2);
  transform-origin: right center;
}

.buttonRightIcon {
  transform: scale(2);
  transform-origin: left center;
}
