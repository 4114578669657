@import 'src/styles/services';

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.content {
  display: flex;
  gap: 30px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include media-width-down($screen-xs) {
    gap: 20px;
  }
}

.steps {
  flex-grow: 1;
}

.stepsTitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: $color-white;

  @include media-width-down($screen-xs) {
    font-size: 20px;
    line-height: 24.38px;
  }
}

.stepsList {
  margin: 0;
  padding: 0 0 0 28px;
  color: $color-white;
}

.step {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;

  @include media-width-down($screen-xs) {
    font-size: 16px;
    line-height: 22.4px;
  }

  & + & {
    margin-top: 1em;

    @include media-width-down($screen-xs) {
      margin-top: 1.3em;
    }
  }
}

.aside {
  width: 200px;
  flex-shrink: 0;

  @include media-width-down($screen-850) {
    display: none;
  }
}

.qrCode {
  width: 100%;
  background-color: $color-white;
  border-radius: 12px;
  padding: 12px;
  aspect-ratio: 1;
  display: flex;
}

.qrCodeSvg {
  width: 100%;
  height: 100%;
}

.qrCodeDescription {
  font-size: 18px;
  line-height: 21.6px;
  color: $color-white;
  opacity: 0.5;
}

.instructions {
  text-decoration: underline;
}

.popupWrapper {
  background-image: url('/assets/app/scratchBg.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}

.popupCornerScratch {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
  opacity: 0.7;
  transform-origin: left top;

  @include media-width-down($screen-xs) {
    transform: scale(0.4);
  }
}

.downloadButton {
  @include media-width-down($screen-850) {
    align-self: center;
  }
}
