@import 'src/styles/services';

.root {
  background-image: url('/assets/app/scratchBg.webp');
  background-repeat: repeat;
  background-blend-mode: multiply;

  &:before {
    background-image: radial-gradient(50% 50% at 50% 50%, #ff0000 52.57%, rgba(255, 0, 0, 0) 100%);
    background-position-x: 150px;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    width: 53%;
    aspect-ratio: 1;
    right: 0;
    top: -50px;
    opacity: 0.2;

    @include media-width-down($screen-md) {
      display: none;
    }
  }
}

.top {
  display: flex;
  flex-direction: column;
  padding-inline: 130px;
  padding-block-start: 100px;
  position: relative;
  z-index: 0;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 580px;

  @include media-width-down($screen-md) {
    padding-inline: 32px;
    padding-block-start: 84px;
    min-height: 564px;
  }

  @include media-width-down($screen-sm) {
    padding-inline: 42px;
    padding-block-start: 20px;
    min-height: 440px;
  }

  @include media-width-down($screen-xs) {
    padding-inline: 16px;
    padding-block-start: 30px;
    display: block;
    min-height: unset;
  }
}

.textContent {
  z-index: 2;
}

.title,
.subtitle {
  font-weight: 900;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-white;

  @include media-width-down($screen-xs) {
    text-align: center;
  }
}

.title {
  font-size: 80px;
  line-height: 1;
  max-width: 700px;

  @include media-width-down($screen-sm) {
    font-size: 40px;
    max-width: 500px;
  }

  @include media-width-down($screen-xs) {
    max-width: unset;
  }
}

.subtitle {
  font-size: 22px;
  line-height: 24px;
  margin-block-start: 18px;

  @include media-width-down($screen-md) {
    font-size: 20px;
    margin-block-start: 16px;
  }

  @include media-width-down($screen-xs) {
    font-size: 18px;
    margin-block-start: 10px;
  }
}

.downloads {
  margin-block-start: 40px;
  display: flex;
  gap: 50px;

  @include media-width-down($screen-sm) {
    margin-block-start: 24px;
    flex-direction: column;
    gap: 16px;
  }

  @include media-width-down($screen-xs) {
    margin-block-start: 30px;
    align-items: center;
    gap: 24px;
  }
}

.phonesImage {
  position: absolute;
  right: 7.7%;
  top: 8px;
  width: 716px;
  aspect-ratio: 658 / 589;
  z-index: -1;

  @include media-width-down($screen-lg) {
    right: 2.5%;
  }

  @include media-width-down($screen-md) {
    right: -6.1%;
    top: 2px;
    width: 662px;
  }

  @include media-width-down($screen-1284) {
    right: -10%;
  }

  @include media-width-down($screen-sm) {
    right: 4%;
    top: -15px;
    width: 518px;
  }

  @include media-width-down($screen-850) {
    right: -6%;
  }

  @include media-width-down($screen-xs) {
    position: relative;
    top: unset;
    right: unset;
    margin-block-start: 46px;
    margin-inline: auto;
    width: 369px;
    left: -34px;
    aspect-ratio: 757 / 697;
  }
}

.footerText {
  font-size: 50px;
  line-height: 1;
  text-align: center;
  color: $color-red;
  margin-inline: auto;
  margin-block: 60px;
  max-width: 700px;
  text-transform: uppercase;

  @include media-width-down($screen-sm) {
    margin-block: 40px;
    font-size: 40px;
  }

  @include media-width-down($screen-xs) {
    margin-block-end: 18px;
    font-size: 28px;
  }
}

.backElements {
  position: absolute;
  z-index: -1;
  width: 882px;
  height: auto;
  right: 3.3%;
  top: 6%;

  @include media-width-down($screen-md) {
    right: -16%;
    top: 0%;
  }

  @include media-width-down($screen-sm) {
    right: -60%;
    top: 1.5%;
  }

  @include media-width-down($screen-xs) {
    display: none;
  }
}

.frontElements {
  position: absolute;
  z-index: 1;
  width: 972px;
  height: auto;
  right: 6.5%;
  top: -1.5%;

  @include media-width-down($screen-lg) {
    right: 1%;
  }

  @include media-width-down($screen-md) {
    width: 865px;
    right: -10.3%;
    top: -4.3%;
  }

  @include media-width-down($screen-sm) {
    width: 548px;
    right: -4.5%;
    top: 2%;
  }

  @include media-width-down($screen-xs) {
    width: 610px;
    top: 26.5%;
    z-index: -1;
    right: unset;
    left: 50%;
    transform: translateX(-50.5%);
  }
}
