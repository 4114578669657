@import 'src/styles/services';

.benefits {
  --benefits-total: 3; // in case if something change

  display: flex;
  gap: 20px;
  z-index: 1;
  position: relative;
  margin-block-start: 60px;

  @include media-width-down($screen-850) {
    width: 100%;
  }

  @include media-width-down($screen-sm) {
    gap: 16px;
    margin-block-start: 40px;
  }

  @include media-width-down($screen-xs) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-block-start: 16px;
  }
}

.benefit {
  width: 369px;
  height: 96px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-inline-start: 16px;
  padding-inline-end: 140px;
  padding-block: 24px;

  @include media-width-down($screen-1284) {
    width: 241px;
    font-size: 18px;
    line-height: 20px;
    padding-inline-end: 50px;
    padding-block: 28px;
  }

  @include media-width-down($screen-850) {
    flex-basis: calc(100% / var(--benefits-total));
    width: auto;
    flex-grow: 1;
    max-width: 319px;
  }

  @include media-width-down($screen-xs) {
    flex-basis: unset;
    width: 100%;
    padding-inline-end: 120px;
  }
}

.benefitBg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border-radius: 10px;
  background-color: $color-black;
}

.benefitIcon {
  position: absolute;
  right: 13px;
  bottom: 13px;
  z-index: -1;
  transform-origin: right bottom;

  @include media-width-down($screen-1284) {
    transform: scale(0.6167);
    right: 16px;
    bottom: 48px;
  }

  @include media-width-down($screen-xs) {
    transform: scale(0.7833);
    right: 23px;
    bottom: 18px;
  }
}
