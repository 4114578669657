@import 'src/styles/services';

.root {
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;

    @include media-width-down($screen-md) {
      display: none;
    }
  }

  &:before {
    left: 0;
    bottom: 3%;
    width: 94px;
    height: 153px;
    background-image: url('/assets/app/leftCoin.webp');
  }

  &:after {
    right: 0;
    top: 36%;
    width: 71px;
    height: 127px;
    background-image: url('/assets/app/rightCoin.webp');
  }
}

.container {
  position: relative;
  padding-block-start: 120px;
  margin-inline: auto;
  max-width: 1140px;
  padding-inline: 20px;

  @include media-width-down($screen-sm) {
    padding-block-start: 86px;
  }

  @include media-width-down($screen-xs) {
    padding-inline: 16px;
    padding-block-start: 36px;
  }
}

// Hide bottom line
.tabs:before,
.tab:before {
  display: none;
}

.tab {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.05em;
  overflow: visible;
  padding-block: 18px;
  flex-basis: 100%;

  @include media-width-down($screen-xs) {
    font-size: 18px;
    padding-block: 13px;
  }
}

.tabLabel {
  position: relative;
  color: $color-white;
}

.tabLabelIcon {
  position: absolute;
  right: 100%;
  bottom: -8px;
  margin-right: 20px;
  pointer-events: none;
  transform-origin: right bottom;

  @include media-width-down($screen-xs) {
    transform: scale(0.4444);
    bottom: 16px;
    margin-right: -6px;
  }
}

.tabActive {
  background-color: #1f2024;
}

.tabContent {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #1f2024;
  padding-inline: 30px;
  padding-block: 30px;
  position: relative;

  &:has(~ [hidden]) {
    border-top-right-radius: 20px;
  }

  &[hidden] ~ & {
    border-top-left-radius: 20px;
  }

  @include media-width-down($screen-xs) {
    padding-inline: 20px;
  }
}
