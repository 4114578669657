@import 'src/styles/services';

.root {
  position: relative;
  padding: 22px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 242px;
  height: 80px;
  z-index: 0;
}

.label {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: $color-white;
  line-height: 1.3;
}

.title {
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.description {
  font-size: 18px;
  font-weight: 400;
}

.bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  object-fit: contain;
}

.iosBg {
  color: $color-red;
}

.androidBg {
  color: $color-android;
}

.icon {
  width: 44px;
  height: 44px;
  position: relative;
  top: -2px;
}
